import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2c3a94a1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "node-body-content" }
const _hoisted_2 = {
  key: 1,
  class: "placeholder"
}
const _hoisted_3 = {
  key: 0,
  class: "node-error"
}
const _hoisted_4 = { class: "node-footer" }
const _hoisted_5 = { class: "btn" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_app_ellipsis = _resolveComponent("app-ellipsis")!
  const _component_Close = _resolveComponent("Close")!
  const _component_ArrowRight = _resolveComponent("ArrowRight")!
  const _component_Warning = _resolveComponent("Warning")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_insert_button = _resolveComponent("insert-button")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({ node: true, root: _ctx.isRoot || !_ctx.show, 'node-error-state': _ctx.showError })
  }, [
    (_ctx.show)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('selected'))),
          class: _normalizeClass({ 'node-body': true, error: _ctx.showError })
        }, [
          _createElementVNode("div", null, [
            _createElementVNode("div", {
              class: "node-body-header",
              style: _normalizeStyle({ 'background-color': _ctx.headerBgc })
            }, [
              ((_ctx.headerIcon || '') !== '')
                ? (_openBlock(), _createBlock(_component_el_icon, {
                    key: 0,
                    size: "1.6em"
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.headerIcon)))
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_app_ellipsis, {
                class: "name pl-1",
                "hover-tip": "",
                content: _ctx.title
              }, null, 8, ["content"]),
              (!_ctx.isRoot && _ctx.diagramMode !== 'viewer')
                ? (_openBlock(), _createBlock(_component_el_icon, {
                    key: 1,
                    class: "el-icon-close",
                    size: "1.6em",
                    style: {"float":"right"},
                    onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('delNode')), ["stop"]))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Close)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ], 4),
            _createElementVNode("div", _hoisted_1, [
              (_ctx.leftIcon)
                ? (_openBlock(), _createBlock(_component_el_icon, {
                    key: 0,
                    size: "1em"
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.leftIcon)))
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              ((_ctx.content || '').trim() === '')
                ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.placeholder), 1))
                : (_openBlock(), _createBlock(_component_app_ellipsis, {
                    key: 2,
                    row: 3,
                    content: _ctx.content
                  }, null, 8, ["content"])),
              (_ctx.diagramMode !== 'viewer')
                ? (_openBlock(), _createBlock(_component_el_icon, {
                    key: 3,
                    size: "1em"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ArrowRight)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            (_ctx.showError)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createVNode(_component_el_tooltip, {
                    effect: "dark",
                    content: _ctx.errorInfo,
                    placement: "top-start"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_icon, { size: "1em" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Warning)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["content"])
                ]))
              : _createCommentVNode("", true)
          ])
        ], 2))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _withDirectives(_createVNode(_component_insert_button, {
          onInsertNode: _cache[2] || (_cache[2] = (type) => _ctx.$emit('insertNode', type))
        }, null, 512), [
          [_vShow, _ctx.diagramMode !== 'viewer']
        ])
      ])
    ])
  ], 2))
}