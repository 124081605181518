import { Options } from "vue-class-component";
import BaseFunction from "@/mixins/BaseFunction";
@Options({
    name: "flowable-components-insert-button",
    emits: ["insertNode"],
})
export default class InsertButton extends BaseFunction {
    get selectedNode() {
        return this.$store.getters["flowable/selectedNode"];
    }
    set selectedNode(val) {
        this.$store.dispatch("flowable/setSelectedNode", val).then();
    }
    public addApprovalNode() {
        this.$emit("insertNode", "APPROVAL");
    }
    public addCcNode() {
        this.$emit("insertNode", "CC");
    }
    public addDelayNode() {
        this.$emit("insertNode", "DELAY");
    }
    public addConditionsNode() {
        this.$emit("insertNode", "CONDITIONS");
    }
    public addConcurrentsNode() {
        this.$emit("insertNode", "CONCURRENTS");
    }
    public addTriggerNode() {
        this.$emit("insertNode", "TRIGGER");
    }
}
